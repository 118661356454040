import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  ChartColumn,
  CircleDollarSign,
  Users,
  Wallet,
  WalletMinimal,
  Calendar,
  Text,
  ChevronDown,
} from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const CustomInputWithDropdown = ({
  options,
  value,
  onChange,
  onSelect,
  placeHolder,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div classNamed="relative">
      <div className="flex border p-2 items-center">
        <input
          type="text"
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setTimeout(() => setIsFocused(false), 200)}
          placeholder={placeHolder}
          className="border-gray-200 py-0.5 px-0.5 placeholder-gray-300 focus:border-orange-400 duration-500 w-full"
        />
        <ChevronDown className="size-5 text-orange-500" />
      </div>
      {isFocused && (
        <div className="absolute z-10 w-full bg-white border border-gray-200 rounded mt-1">
          {options.map((option, index) => (
            <div
              key={index}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                onSelect(option);
                setIsFocused(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const DashboardTable = ({ visibleColumns, data }) => {
  const columns = [
    { key: "date", label: "Tarih" },
    { key: "clicks", label: "Tıklamalar" },
    { key: "registrations", label: "Kayıtlar" },
    { key: "deposits", label: "Depozitolar" },
    { key: "avgFD", label: "Orta FD" },
    { key: "fdAmount", label: "FD tutarı" },
    { key: "fdHold", label: "FD hold" },
    { key: "fdApproved", label: "FD approved" },
    { key: "fdRejected", label: "FD Rejected" },
    { key: "playerCount", label: "Oyuncu sayısı" },
    { key: "repeatedDeposits", label: "Tekrarlanan Depozitolar" },
    { key: "uniqueRD", label: "Benzersiz RD" },
    { key: "avgRD", label: "Orta RD" },
    { key: "rdAmount", label: "RD tutarı" },
    { key: "depositAmount", label: "Depozito miktarları" },
    { key: "betAmount", label: "Bahis tutarı" },
    { key: "earnings", label: "Kazanç miktarı" },
    { key: "lossAmount", label: "Kayıp miktarı" },
    { key: "cpa", label: "CPA" },
    { key: "revShare", label: "RevShare" },
    { key: "bettingCompanyRevenue", label: "Bahis firması geliri" },
    { key: "total", label: "Toplam" },
    { key: "arppu", label: "ARPPU" },
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full text-sm border-collapse bg-white">
        <thead>
          <tr className="bg-gray-700 text-white">
            {columns.map(
              (col) =>
                visibleColumns[col.key] && (
                  <th key={col.key} className="border font-light px-4 py-2">
                    {col.label}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="bg-gray-100 font-medium">
              {columns.map(
                (col) => {
                  const date = new Date(row[col.key] * 1000);
                  return visibleColumns[col.key] && (
                    <td key={col.key} className="border text-center px-4 py-2">
                      {col.key === "date" ? date.toLocaleDateString(): row[col.key]}
                    </td>
                  )
                }
              )}
            </tr>
          ))}
          <tr className="bg-gray-700 text-white font-light">
            <td className="border px-4 py-2 font-light text-white">
              TOPLAMLARI
            </td>
            {columns.slice(1).map(
              (col) => {
                const registrations = (col.key === "registrations" || col.key === "deposits") ? data.reduce(
                  (sum, row) => {
                    return sum + row.registrations;
                  },
                  0
                ) : 0;
                const deposits = (col.key === "deposits") ? data.reduce(
                  (sum, row) => {
                    return sum + row.deposits;
                  },
                  0
                ) : 0;
                const $ = value => (typeof value === "number" && isNaN(value)) ? 0 : value;
                return visibleColumns[col.key] && (
                  <td key={col.key} className="border text-center px-4 py-2">
                    {col.key === "clicks"
                      ? data.reduce((sum, row) => sum + row[col.key], 0)
                      : col.key === "registrations"
                        ? `${registrations} (${$(
                          registrations / data.reduce(
                            (sum, row) =>
                              sum +
                              parseFloat(
                                row.clicks
                              ),
                            0
                          ) * 100).toFixed(2)}%)`
                        : (col.key === "deposits") ? `${deposits} (${$(deposits / registrations * 100).toFixed(2)}%)`
                          : (col.key === "avgFD" ||
                            col.key === "fdAmount" ||
                            col.key === "avgRD" ||
                            col.key === "rdAmount" ||
                            col.key === "depositAmount" ||
                            col.key === "betAmount" ||
                            col.key === "earnings" ||
                            col.key === "lossAmount" ||
                            col.key === "cpa" ||
                            col.key === "revShare" ||
                            col.key === "bettingCompanyRevenue" ||
                            col.key === "total" ||
                            col.key === "arppu")
                            ? `$${data
                              .reduce(
                                (sum, row) =>
                                  sum + row[col.key],
                                0
                              )
                              .toFixed(2)}`
                            : data.reduce((sum, row) => sum + row[col.key], 0)}
                  </td>
                )
              }
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [sub1, setSub1] = useState("");
  const [sub2, setSub2] = useState("");
  const [sub3, setSub3] = useState("");
  const [sub4, setSub4] = useState("");
  const [sub5, setSub5] = useState("");
  const [dropDownShow, setDropDownShow] = useState(false);
  const options = ["Akış", "BD", "apk", "223425"];
  const options2 = ["Ülke", "Türkiye"];
  const [visibleColumns, setVisibleColumns] = useState({
    date: true,
    clicks: true,
    registrations: true,
    deposits: true,
    avgFD: true,
    fdAmount: true,
    fdHold: true,
    fdApproved: true,
    fdRejected: true,
    playerCount: true,
    repeatedDeposits: true,
    uniqueRD: true,
    avgRD: true,
    rdAmount: true,
    depositAmount: true,
    betAmount: true,
    earnings: true,
    lossAmount: true,
    cpa: true,
    revShare: true,
    bettingCompanyRevenue: true,
    total: true,
    arppu: true,
  });

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };
  const [data, setData] = useState({
    cpa: 0,
    players: 0,
    deposits: 0,
    depositAmount: 0,
    revenue: 0,
    today: 0,
    yesterday: 0,
    weekly: 0,
    monthly: 0,
    items: []
  });
  const refreshData = (from, to) => {
    axios.get(`https://paynowolympuspredictor.online/affiliates/mostbet/${from}/${to}`).then(response =>
      setData(response.data)
    );
  }
  useEffect(() => {
    console.log(data)

  }, [data])
  useEffect(() => refreshData(0, 0), []);
  return (
    <div>
      <Header data={data} />
      <br />
      <br />
      <div className="p-4 bg-[#ebeff3]">
        <p className="font-semibold">DASHBOARD</p>
      </div>
      <div className="p-5 bg-[#e1e7ed]">
        <div className="bg-white py-3 px-5 border">
          <h1 className="text-sm font-bold">OYUNCULAR</h1>
          <br />
          <div className="flex items-center justify-between">
            <Users className="size-7 fill-white hover:ring-8 duration-200 p-1 bg-blue-400 rounded-full text-white " />
            <h1 className="text-lg">{data.players}</h1>
          </div>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">DEPOZİTLER</h1>
          <br />
          <div className="flex items-center justify-between">
            <Wallet className="size-7 hover:ring-8 ring-emerald-400 duration-200 p-1 bg-emerald-300 rounded-full text-white " />
            <h1 className="text-lg">{data.deposits}</h1>
          </div>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">DEPOZİT MİKTARI</h1>
          <br />
          <div className="flex items-center justify-between">
            <CircleDollarSign className="size-7 hover:ring-8 ring-red-400 duration-200 p-1 bg-red-300 rounded-full fill-white text-red-500 " />
            <h1 className="text-lg">${data.depositAmount.toFixed(2)}</h1>
          </div>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">GELİRİNİZ</h1>
          <br />
          <div className="flex items-center justify-between">
            <WalletMinimal className="size-7 hover:ring-8 ring-purple-400 duration-200 p-1 bg-purple-300 rounded-full fill-white text-purple-500 " />
            <h1 className="text-lg">${data.revenue.toFixed(2)}</h1>
          </div>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">BUGÜN</h1>
          <br />
          <h1 className="text-lg w-full text-end">${data.today.toFixed(2)}</h1>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">DÜN</h1>
          <br />
          <h1 className="text-lg w-full text-end">${data.yesterday.toFixed(2)}</h1>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">HAFTALIK</h1>
          <br />
          <h1 className="text-lg w-full text-end">${data.weekly.toFixed(2)}</h1>
        </div>
        <div className="bg-white mt-5 py-3 px-5 border">
          <h1 className="text-sm font-bold">AYLIK</h1>
          <br />
          <h1 className="text-lg w-full text-end">${data.monthly.toFixed(2)}</h1>
        </div>
        <div className="bg-white py-3 px-5 mt-5 border">
          <div className="flex items-center justify-between">
            <h1 className="font-bold">DETAYLI BİLGİLER</h1>
            <div className="flex items-center gap-1">
              <ChartColumn className="size-4 text-orange-500" />
              <h1 className="font-bold">Bütün istatistikler</h1>
            </div>
          </div>
          <div className="mt-3 flex items-center justify-center w-full gap-2">
            <div className="flex w-full justify-center font-light text-sm mx-auto">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={
                  <div className="flex px-1 border w-full items-center">
                    <p> {startDate.toLocaleDateString()}</p>
                    <button className=" p-1.5 flex items-center gap-2">
                      <Calendar className="text-orange-400 ml-5" />
                    </button>
                  </div>
                }
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={
                  <div className="flex px-1 border w-full items-center">
                    <p> {endDate.toLocaleDateString()}</p>
                    <button className="p-1.5 flex items-center gap-2">
                      <Calendar className="text-orange-400 ml-5" />
                    </button>
                  </div>
                }
              />
            </div>
          </div>
          <div className="grid gap-2 grid-cols-2">
            <div className="mt-5">
              <CustomInputWithDropdown
                options={options}
                value={inputValue}
                placeHolder={"Akış"}
                onChange={(e) => setInputValue(e.target.value)}
                onSelect={(option) => setInputValue(option)}
              />
            </div>
            <div className="mt-5">
              <CustomInputWithDropdown
                options={options2}
                value={inputValue2}
                placeHolder={"Ülke"}
                onChange={(e) => setInputValue2(e.target.value)}
                onSelect={(option) => setInputValue2(option)}
              />
            </div>
            <div className="mt-5">
              <input
                type="text"
                placeholder="Sub1"
                value={sub1}
                onChange={(e) => setSub1(e.target.value)}
                className="py-2 placeholder-gray-300 border-gray-200 focus:border-orange-400 duration-500 border px-2 w-full"
              />
            </div>
            <div className="mt-5">
              <input
                type="text"
                placeholder="Sub2"
                value={sub2}
                onChange={(e) => setSub2(e.target.value)}
                className="py-2 placeholder-gray-300 border-gray-200 focus:border-orange-400 duration-500 border px-2 w-full"
              />
            </div>
            <div className="mt-5">
              <input
                type="text"
                placeholder="Sub3"
                value={sub3}
                onChange={(e) => setSub3(e.target.value)}
                className="py-2 placeholder-gray-300 border-gray-200 focus:border-orange-400 duration-500 border px-2 w-full"
              />
            </div>
            <div className="mt-5">
              <input
                type="text"
                placeholder="Sub4"
                value={sub4}
                onChange={(e) => setSub4(e.target.value)}
                className="py-2 placeholder-gray-300 border-gray-200 focus:border-orange-400 duration-500 border px-2 w-full"
              />
            </div>
          </div>
          <div className="mt-5 flex items-center gap-5">
            <input
              type="text"
              placeholder="Sub5"
              value={sub5}
              onChange={(e) => setSub5(e.target.value)}
              className="py-2 placeholder-gray-300 border-gray-200 focus:border-orange-400 duration-500 border px-2 w-full"
            />
            <button className="w-32 py-2 bg-orange-600 text-sm text-white rounded-sm" onClick={() =>
              refreshData(Math.floor(startDate.getTime() / 1000), Math.floor(endDate.getTime() / 1000))
            }>
              Yenile
            </button>
          </div>

          <div>
            <button
              onClick={() => setDropDownShow(!dropDownShow)}
              className="w-full items-center justify-end flex mt-5"
            >
              <div className="flex p-1 items-center gap-1 border">
                <Text className="size-5 text-orange-500" />
                <ChevronDown className="size-5 text-orange-500" />
              </div>
            </button>
            {dropDownShow && (
              <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div
                  className="py-1 h-60 overflow-y-scroll"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {Object.keys(visibleColumns).map((col) => (
                    <label key={col} className="flex items-center px-4 py-2">
                      <input
                        type="checkbox"
                        checked={visibleColumns[col]}
                        onChange={() => handleColumnVisibilityChange(col)}
                        className="accent-orange-500"
                      />
                      <span className="ml-2">{col}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <DashboardTable visibleColumns={visibleColumns} data={data.items} />
      </div>
    </div>
  );
};

export default Dashboard;
