import React from "react";
import wallet from "../images/wallet.svg";
import { LogOut, Menu } from "lucide-react";

const Header = ({data}) => {
  return (
    <div className="py-1 px-2 fixed w-full bg-white z-50 flex items-center">
      <Menu />
      <div className="flex mx-auto flex-col gap-1 items-start">
        <div className="flex gap-1 items-center">
          <img src={wallet} className="size-4" />
          <h3 className="text-xs font-sans">
            Revshare: <span>USD {data.revenue.toFixed(2)}</span>
          </h3>
        </div>
        <div className="flex gap-1 items-center">
          <img src={wallet} className="size-4" />
          <h3 className="text-xs font-sans">
            CPA: <span>USD {data.cpa.toFixed(2)}</span>
          </h3>
        </div>
        <p className="text-xs text-orange-500 underline">
          İş hakkında geri bildirim
        </p>
      </div>
      <div className="flex items-center gap-2">
        <p className="border-r pr-4">TR</p>
        <LogOut className="size-5 text-gray-500" />
      </div>
    </div>
  );
};

export default Header;
